import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s03_1_2_1 : false,
      s03_1_2_2 : false,
      s03_1_2_3 : false,
      s03_1_2_4 : false,
      s03_1_2_5 : false,
      s03_1_2_6 : false
    }

    this.searchInit = {
      filter1Init: 2,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ATAG Waypoint2050シナリオ１のCO2パス（航空技術・運航技術の導入を推進するシナリオ）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_1"}>
                <img src={'../../images/chart/s03-1-2-1.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_1}
                close={this.closeModal}
                name={"s03_1_2_1"}
                src={'/images/chart/s03-1-2-1.png'}
                alt="ATAG Waypoint2050シナリオ１のCO2パス（航空技術・運航技術の導入を推進するシナリオ）"
                width={947}
                height={900} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG "WAYPOINT2050"</a>(2021年9月)</p>
                <p className={chartStyles.chartExplain}>図中のアルファベットはWAYPOINT2050における各分野のシナリオを表す記号</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ATAG Waypoint2050シナリオ２のCO2パス（SAFの導入を強化するシナリオ）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_2"}>
                <img src={'../../images/chart/s03-1-2-2.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_2}
                close={this.closeModal}
                name={"s03_1_2_2"}
                src={'/images/chart/s03-1-2-2.png'}
                alt="ATAG Waypoint2050シナリオ２のCO2パス（SAFの導入を強化するシナリオ）"
                width={943}
                height={900} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG "WAYPOINT2050"</a>(2021年9月)</p>
                <p className={chartStyles.chartExplain}>図中のアルファベットはWAYPOINT2050における各分野のシナリオを表す記号</p>
              </div>
            </div>
          </div>
        
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ATAG Waypoint2050シナリオ３のCO2パス（航空技術を野心的に導入するシナリオ）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_3"}>
                <img src={'../../images/chart/s03-1-2-3.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_3}
                close={this.closeModal}
                name={"s03_1_2_3"}
                src={'/images/chart/s03-1-2-3.png'}
                alt="ATAG Waypoint2050シナリオ３のCO2パス（航空技術を野心的に導入するシナリオ）"
                width={974}
                height={901} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://aviationbenefits.org/media/167417/w2050_v2021_27sept_full.pdf" target="_blank" rel="noreferrer noopener">ATAG "WAYPOINT2050"</a>(2021年9月)</p>
                <p className={chartStyles.chartExplain}>図中のアルファベットはWAYPOINT2050における各分野のシナリオを表す記号</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}></div>
          </div>
        
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>航続距離別に適用が想定される推進系のマッピング（Cranfield大学）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_4"}>
                <img src={'../../images/chart/s03-1-2-4.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_4}
                close={this.closeModal}
                name={"s03_1_2_4"}
                src={'/images/chart/s03-1-2-4.png'}
                alt="航続距離別に適用が想定される推進系のマッピング（Cranfield大学）"
                width={1148}
                height={412} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.fzt.haw-hamburg.de/pers/Scholz/dglr/hh/text_2021_05_06_Hydrogen.pdf" target="_blank" rel="noreferrer noopener">Cranfield University "Enabling Cryogenic Hydrogen-Based CO2-Free Air Transport"</a>（2021年5月）</p>
                <p className={chartStyles.chartExplain}>縦軸が全世界に対するシェア、横軸が航続距離、バブルは適用が想定される推進系の種類</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}></div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>EU Clean Aviation JUの水素航空機開発ロードマップ</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_5"}>
                <img src={'../../images/chart/s03-1-2-5.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_5}
                close={this.closeModal}
                name={"s03_1_2_5"}
                src={'/images/chart/s03-1-2-5.png'}
                alt="EU Clean Aviation JUの水素航空機開発ロードマップ"
                width={1200}
                height={742} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.clean-aviation.eu/sites/default/files/2022-01/CAJU-GB-2021-12-16-SRIA_en.pdf" target="_blank" rel="noreferrer noopener">The Clean Aviation Joint Undertaking "CLEAN AVIATION Strategic Research and Innovation Agenda"</a>（2021年12月）</p>
                <p className={chartStyles.chartExplain}>EUにおける航空分野の研究開発パートナーシップであるThe Clean Aviation Joint Undertakingによる水素航空機の技術開発計画</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}></div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>各技術のTRL・導入見通し年</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s03_1_2_6"}>
                <img src={'../../images/chart/s03-1-2-6.png'} alt="" width={559} />
              </button>
              <ImageBox
                open={this.state.s03_1_2_6}
                close={this.closeModal}
                name={"s03_1_2_6"}
                src={'/images/chart/s03-1-2-6.png'}
                alt="各技術のTRL・導入見通し年"
                width={1200}
                height={749} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）IATA "Aircraft Technology Roadmap to 2050"（2019年12月）、ATAG "WAYPOINT2050"(2021年9月)、IEA "Energy Technology Perspective Clean Energy Technology Guide"(2020年7月）、ICAO "Report on the feasibility of a long-term aspirational goal(LTAG) for international cicil aviation CO2 emission reductions Appendix M3 Technology Sub Group Report"（2022年3月）、IATA "Net-Zero Carbon Emissions by 2050" (2021年10月)を基に作成</p>
                <p className={chartStyles.chartExplain}></p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}></div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`